import {observe} from '@github/selector-observer'
import {on} from 'delegated-events'

// Select "Pay with" "Credit or debit card" or "PayPal account" radio button.
on('click', '.js-payment-methods .js-payment-method', handlePaymentMethodChange)
on('click', '.js-payment-method-href', handlePaymentMethodChange)

function handlePaymentMethodChange(event: Event) {
  if (!(event.currentTarget instanceof Element)) return
  const container = event.currentTarget.closest<HTMLElement>('.js-payment-methods')!
  const selectedTab = event.currentTarget.getAttribute('data-selected-tab')!

  const paymentMethodEls = container.querySelectorAll('.js-selected-payment-method')
  for (const el of paymentMethodEls) {
    el.classList.remove('active')
  }

  container.querySelector<HTMLElement>(`.${selectedTab}`)!.classList.add('active')
  const paymentMethodTypeEl = container.querySelector<HTMLInputElement>('.js-payment-method-type')!

  if (selectedTab === 'pay-with-paypal') {
    paymentMethodTypeEl.value = 'paypal'
  } else if (selectedTab === 'pay-with-azure') {
    paymentMethodTypeEl.value = 'azure'
  } else {
    paymentMethodTypeEl.value = 'credit_card'
    const editButton = container.querySelector('.js-sponsors-payment-method-update-confirmation-dialog')
    editButton?.classList.add('has-removed-contents')
  }

  // For Zuora enabled users show outer continue button when PayPal is selected
  const billingSection = container.closest('.js-billing-section')
  if (billingSection) {
    if (selectedTab === 'pay-with-paypal') {
      billingSection.classList.add('PaymentMethod--paypal')
      billingSection.classList.remove('PaymentMethod--creditcard')
      billingSection.classList.remove('PaymentMethod--azure')
      billingSection.classList.remove('PaymentMethod--creditcard-added')
      const existingCardContainer = document.querySelector<HTMLElement>('.js-zuora-billing-info')!
      const newCardContainer = document.querySelector<HTMLElement>('.js-zuora-payment-page')!
      /* eslint-disable-next-line github/no-d-none */
      existingCardContainer.classList.add('d-none')
      /* eslint-disable-next-line github/no-d-none */
      newCardContainer.classList.remove('d-none')
    } else if (selectedTab === 'pay-by-credit-card') {
      billingSection.classList.remove('PaymentMethod--paypal')
      billingSection.classList.remove('PaymentMethod--azure')
      billingSection.classList.add('PaymentMethod--creditcard')
    } else if (selectedTab === 'pay-by-azure') {
      billingSection.classList.remove('PaymentMethod--paypal')
      billingSection.classList.remove('PaymentMethod--creditcard')
      billingSection.classList.add('PaymentMethod--azure')
    }
  }
}

// Ensure only the active payment option is in the document
observe('.js-selected-payment-method:not(.active)', {
  add(el) {
    el.classList.add('has-removed-contents')
  },
  remove(el) {
    el.classList.remove('has-removed-contents')
  },
})

observe('.js-billing-payment-methods', function (el) {
  el.classList.remove('disabled')
})
